module.exports = {
  comm: {
    home: 'Home',
    pricing: 'Pricing',
    blog: 'Voice Clone&API',
    language: 'language:',
    select_language: 'select language',
    txt2voice: 'text to speech',
    voice2txt: 'speech to text',
    voiceclone: 'voice cloning',
    video2txt: 'video to text',
    login: 'log in',
    login_desc: 'Login to access your TikTok Voice Generator account',
    logout: 'log out',
    footer_help: 'Need help? mail us:',
    footer_mail: 'tiktokaivoicetool@gmail.com'
  },
  txt2voice: {
    title: 'TikTok Voice Generator: Generate funny TikTok AI Voices',
    description: 'Generate funny TikTok AI voices for free such as jessie voice，C3PO voice，ghostface voice,siri voice…',
    keywords: 'TikTok Voice generator,TikTok AI Voice,Tik Tok Voice Generator ',
    main_title_p01: 'TikTok Voice Generator - Best tiktok AI voice',
    main_title_p02: 'Generate funny TikTok voices: jessie voice，C3PO voice，ghostface voice，siri voice and so on',
    main_textarea_holder: 'Type or paste text here',
    main_genvoice: 'generate',
    main_generating: 'generating',
    main_input_empty: 'please type or paste text ',
    daily_usage_limit_msg: '1) The daily maximum usage limit has been reached. Please come back tomorrow. 2) If you urgently need more usage, please email us.',
    text_max_prompt1: 'Please limit the word count to',
    text_max_prompt2: 'words or less!',
    popup_nologin_title: 'Upgrade Your Subscription Plan',
    popup_nologin_desc: 'Unlock more voice generation time by upgrading to a premium subscription.',
    popup_nologin_btntext: 'Explore Subscription Plans',
    popup_nosub_title: 'Upgrade Your Subscription Plan',
    popup_nosub_desc: 'Unlock more voice generation time by upgrading to a premium subscription.',
    popup_nosub_btntext: 'Explore Subscription Plans',
    popup_sublimit_title: 'Subscription exhausted, please upgrade',
    popup_sublimit_desc: 'Enhance your experience with our premium features and unlimited access.',
    popup_sublimit_btntext: 'Go to purchase more',
  },
  pricing: {
    new_features_alert: "📣 We’ve launched 🎙️ Voice Cloning and 🤖 API services for paid members! （After payment, email tiktokaivoicetool@gmail.com for help with these features）. Additionally, feel free to email us for any customization requests😃",
    pricing_title: 'From individual creators to the biggest enterprises, we have a plan for you.',
    pricing_desc: '100+ natural, human-like voices. 15+  languages.and almost the lowest pricing for everyone',
    sub_free_name: 'Free',
    sub_free_desc: 'For individuals who want to try out the most advanced AI audio',
    sub_free_content: [
	    		'1000 characters per month limit',
	    		'5 generations per day',
	    	      ],
    sub_starter_name: 'Starter',
    sub_starter_desc: 'For creators making premium content for global audiences',
    sub_starter_content: [
              '1 voice clones',
	    		    '300000 characters per month limit(approximately 7hours of audio)',
	    		    'NO Ads',
              'Unlimited Downloads',
	    		    '72h email support',
	    		    'Priority access to new voices and features',
               		 ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'For creators ramping up their content production',
    sub_pro_content: [
              'API access',
              '3 voice clones',
	    	      '1000000 characters per month limit (approximately 24hours of audio)',
              'NO Ads',
	    	      'Unlimited Downloads',
	    	      '48h email support',
	    	      'Priority access to new voices and features',
	    	     ],
    period_year: 'Yearly',
    period_month: 'Monthly',
    period_month_short: 'mo',
    billed_year: 'Billed yearly',
    most_popular: 'MOST POPULAR',
    discount_quantity: 'Save $48',
    buy_btntext: 'GET STARTED',
    pay_succ: 'Payment Successful!',
    pay_succ_desc: 'Thank you for your purchase. Your transaction has been completed successfully.',
    pay_succ_btntext: 'Start Using TikTok Voice',
    pay_fail: 'Payment Failed!',
    pay_fail_desc: "We apologize, but your transaction could not be processed. Please try again or reach out to our support team for assistance at tiktokaivoicetool@gmail.com.",
    pay_fail_btntext: 'Return to Homepage',
  },
  setting: {
    setting: 'Settings',
    setting_title: 'Manage your account, usage, and subscription here.',
    basic_info: 'Basic Info',
    user_name: 'Name',
    user_email: 'Email',
    user_account: 'Account',
    user_subscript: 'Current Plan',
    user_usage: 'Usage',
    manage_subscript: 'Manage Subscription',
    use_limit_promote: "You've used 5000 out of your 10000 monthly character limit.",
    not_logged_in: 'You are not logged in',
    go_to_login: 'Go to login >>',
  },
  login: {
    title: "TikTok Voice Generator",
    subtitle: "Login to access your TikTok Voice Generator account",
    alreadyLoggedIn: "You are already logged in",
    goToHome: "Go to Home"
  },
  faq: {
    quest1: 'TikTok Voice Generator - Best TikTok AI Voice & TikTok text to speech',
    answer1_1: "It's a tiktok AI voice generator tool developed by latest tiktok tts technology,it can generate tiktok ai voice such as the jessie voice(lady voice), siri voice, ghostface voice, C3PO voice(robot voice), Deep voice(story teller), skye voice, warm voice, bestie voice, hero voice(chirisvoice), empathetic voice, serious voice, joey voice, stitch voice, Stormtrooper voice(Star Wars), Rocket voice(Guardians of the Galaxy).",
    answer1_2: "The tiktok AI voices that will be supported soon include: derek voice, trickster voice, austin butler voice, news reporter voice, adam voice, dog voice, miley cyrus voice, alexa voice, alien voice, animal voice, baby voice, computer voice, chipmunk voice, echo voice, npr voice. ",
    answer1_3: 'Additionally, tik tok voice generator will supports more languages and voices.',
    answer1_4: 'If you need a specific voice, please email me.',
    
    quest2: 'What are the advantages of TikTok Voice Generator?',
    answer2_1: '- TikTok Voice Generator can generate various voices, which are often used in TikTok videos.',
    answer2_2: "- TikTok Voice Generator use latest text to speech technology that can generate human-like voice.",
    answer2_3: '- It is more convenient for video editing on PC.',
    answer2_4: "- You can use some voice over that removed in TikTok APP now.",

    quest3: 'How to use TikTok Voice Generator?',
    answer3_1: 'The TikTok Voice Generator is very easy to use:',
    answer3_2: '- Choose the language and voice accent.',
    answer3_3: '- Type the text into the text box,',
    answer3_4: '- Press the generate button and wait a few seconds,',
    answer3_5: '- Play or download the tiktok AI voice.',

    quest4: 'What are the most famous and funny tiktok voices?',
    answer4_1: 'The most famous male tiktok voice is Deep voice, officially called story teller. ',
    answer4_2: "For the funniest tiktok voice, I personally recommend ghostface voice and C3PO voice. These are often used for dubbing funny TikTok videos because they are highly recognizable and can quickly immerse viewers.",
   
    quest5: 'What are the most annoying TikTok voices in TikTok Voice Generator?',
    answer5_1: "There isn't truly an 'annoying' tiktok voice.",
    answer5_2: "It's more about the context and content where the voice is used that may make some people uncomfortable. For instance, some people might find Jessie voice annoying because it appeared too frequently on TikTok during a certain period. However, due to its timbre and quality, it is actually a fantastic tiktok voice effect. Additionally, the tiktok voice actor behind it is very professional and also posts her own videos on TikTok.",
    answer5_3: 'So, I suggest to listen the voices from this tool multiple times, and patiently find the best tiktok voice effect that suits you.',

    quest6: 'Can TikTok Voice Generator  generate jessie voice?',
    answer6_1: '- In the first dropdown menu of TikTok Voice generator tool, select English (US) accent, then in the other dropdown menu, select Jessie voice (voice lady).',
    answer6_2: '- Type the text  into the input box and click the generate button. ',
    answer6_3: '- After waiting about ten seconds, you will hear the  AI voice. In the operation bar, find the button with a downward arrow and click it to download.',

    quest7: 'Can TikTok Voice Generator generate C3PO voice?',
    answer7_1: '- Select English (US) accent, then  choose C3PO voice (robot voice).',
    answer7_2: '- Enter the text and click the generate button.',
    answer7_3: '- After waiting a moment, you will hear the AI voice. ',

    quest8: 'Can TikTok Voice Generator generate ghostface voice?',
    answer8_1: '- Choose English (US), then  choose ghostface voice.',
    answer8_2: '- Type and generate the voice .',
    answer8_3: '- The tikTok AI voice will be generated .',

    quest9: 'How to use TikTok Voice Generator to generate siri voice?',
    answer9_1: '- Select English (US), then click siri voice.',
    answer9_2: '- Put the text  into the input box and press the generate button.',
    answer9_3: '- Then the AI voice will be generated.',

    quest10: 'How can I add tiktok voice effect in tiktok video?',
    answer10_1: 'If you want to know how to use the official tiktok voices on TikTok, I will write a dedicated blog post',
    answer10_2: 'Here, I will explain how to publish your voice to TikTok with TikTok Voice Generator.',
    answer10_3: '1. If you have generated the tiktok voice on your PC and want to upload it to TikTok or any other video editing app on your phone, you need to transfer the voice file to your phone. For iPhones, you can use airDrop for the transfer. For Android phones, I am not very familiar with the process, but you will definitely find methods and tools to do so.',
    answer10_4: '2. Once the voice file is on your phone, you can open TikTok:',
    answer10_5: "- Tap the '+' button at the bottom of the interface, select the video in your phone.",
    answer10_6: '- After uploading the video, find a square icon on the right side of the screen to enter the editing page.',
    answer10_7: "-  At the bottom of the page, find the 'add voice' button and click to select the voice file you just transferred.",
    answer10_8: '- In this interface, you can make some adjustments to the video, and then click the button at the top right corner to publish it.',
    
    quest11: 'Is TikTok Voice Generator free?',
    answer11: 'Yes , TikTok Voice Generator is a free tiktok text to speech generator.',

    quest12: 'Where can I generate funnys TikTok Voice into my TikTok video?',
    answer12: 'You can visit https://tiktokvoice.net/ to use TikTok Voice Generator'
  },
  host: 'https://tiktokvoice.net'
}
