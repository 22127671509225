import Vue from 'vue'
import VueMeta from 'vue-meta'
import { createApp } from './app';

Vue.use(VueMeta)

const { app, store, router, i18n } = createApp();

// 初始化 store 状态
if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__)
  const { i18nState } = window.__INITIAL_STATE__
  i18n.locale = i18nState.locale
  i18n.setLocaleMessage(i18nState.locale, i18nState.messages)
  // console.log("client entry 333, i18n locale:", i18n.locale)
  // console.log("client entry 444, i18n locale:", i18n.getLocaleMessage(i18n.locale))
}

router.onReady(() => {
  app.$mount('#app');
});
